// 160
// import { getReq, postReq } from "./helper.js";
import { baseUrl } from "../../helper/api.js";
let input = document.querySelector("#default-01");
let button = document.querySelector(".btn-primary");
let label = document.querySelector("label");
let title = document.querySelector(".y-block-title");
let descr = document.querySelector(".y-block-des");
let alert = document.querySelector(".form-note-s2");

axios.defaults.withCredentials = true;

window.loginReq = loginReq;
window.pinReq = pinReq;

async function loginReq(event, url) {
  event.preventDefault();
  if (!input.value) {
    alert.textContent = "Please enter your email";
    return;
  }
  url = `${baseUrl}/auth/login`;
  const postdata = { email: input.value };
  input.value = "";
  button.textContent = "Checking account...";
  try {
    const response = await axios.post(url, postdata);
    button.setAttribute("onclick", `pinReq(event)`);
    title.textContent = "Enter your PIN";
    descr.textContent = "Please type the PIN received in the email";
    label.textContent = "PIN";
    button.textContent = "Sign-in with PIN";
    alert.textContent = "";
    input.focus();
    return response;
  } catch (error) {
    alert.classList.add("text-danger");
    alert.textContent = error.response.data.message;
    // button.setAttribute("onclick", "location.pathname='/login'");
    button.textContent = "Login failed. Please try again!";
    console.log({ error });
    return error;
  }
}
async function pinReq(event, url) {
  event.preventDefault();
  if (!input.value) {
    alert.textContent = "Please enter your PIN";
    return;
  }
  url = `${baseUrl}/auth/loginpin`;
  const postdata = { password: input.value };
  input.value = "";
  button.textContent = "Signing in...";
  alert.textContent = "";
  try {
    const response = await axios.post(url, postdata);

    alert.textContent = "";
    location.pathname = "/";
    return response;
  } catch (error) {
    alert.classList.add("text-danger");
    alert.textContent = error.response.data.message;
    // button.setAttribute("onclick", "location.pathname='/login'");
    button.textContent = "Login failed. Please try again!";
    console.log({ error });
    return error;
  }
}
